import axios from 'axios'

const API = '/llamados'

const Llamados = {
    historial(params){
        return axios(`${API}`,{params})
    }, 

    listar(params){
        return axios(`${API}/listar-admin`,{params})
    },   
    mostrar(id_llamado){
        return axios.get(`${API}/mostrar/${id_llamado}`)
    }, 
    eliminar(params){
        return axios.delete(`${API}/eliminar`,{params})
    }, 
    guardar(payload){
        return axios.post(`${API}`,payload)
    },
}

export default Llamados
