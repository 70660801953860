<template>
    <section v-loading="loading" class="llamados-crear mt-4 ml-4">
        <div class="row mt-4 my-4">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'llamados.atencion.listado' }">
                        Llamados de atención
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Nuevo llamado</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid }" ref="validation">
            <div class="row mt-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div class="row">
                        <p class="col-12 f-20 f-500 mb-3">Hacer un llamado de atención</p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:120" name="titulo" tag="div" class="col-12 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Título del llamado</p>
                            <el-input v-model="llamado.titulo" placeholder="Titulo" show-word-limit maxlength="120" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:1000" name="descripción" tag="div" class="col-12 col-sm-12 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Descripción</p>
                            <el-input v-model="llamado.descripcion" placeholder="Descripción" type="textarea" rows="5" show-word-limit maxlength="1000" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:500" name="recomendaciones" tag="div" class="col-12 col-sm-12 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Recomendaciones</p>
                            <el-input v-model="llamado.recomendaciones" placeholder="Recomendaciones" type="textarea" rows="4" show-word-limit maxlength="500" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <ValidationProvider tag="div" name="vivienda" class="col-12 col-sm-6 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Grupo de viviendas</p>
                            <select-grupos @setAgrupacion="setAgrupacion" />    
                        </ValidationProvider>
                        <ValidationProvider tag="div" class="col-12 col-sm-6 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Vivienda</p>
                            <select-viviendas ref="selectViviendas" @setVivienda="setVivienda" />
                        </ValidationProvider>
                        <div class="col-12">
                            <button class="btn btn-general h-32px f-14 f-300 px-5" @click="guardar">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-5 mx-4">
                    <p class="col-12 f-20 f-500 mb-3">Imágenes de soporte</p>
                    <el-upload
                    action="#"
                    list-type="picture-card"
                    :file-list="imagenes"
                    :on-change="handleChange"
                    :multiple="false"
                    :auto-upload="false"
                    >
                        <i slot="default" class="el-icon-plus" />
                        <div slot="file" slot-scope="{file}">
                            <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url" alt=""
                            />
                            <span class="el-upload-list__item-actions">
                                <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                                >
                                    <i class="el-icon-zoom-in" />
                                </span>
                                <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="removeImage(file)"
                                >
                                    <i class="el-icon-delete" />
                                </span>
                            </span>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>  
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
    </section>
</template>
<script>
import Llamados from '~/services/llamados'
export default {
    components:{
    },
    data(){
        return{
            loading: false,
            inputNombre: '',
            llamado: {
                id: null,
                titulo: '',
                descripcion: '',
                recomendaciones: '',
                id_vivienda: '',
                imagenes: [],              
            },
            imagenes:[],
            disabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            idEliminarImagen: null,
        }
    },
    methods:{
        enviarLlamado(){

        },
        verImg(){
            this.$refs.abrirModalVerImg.toggle()
        },
        eliminarImg(idx){
            this.listadoImagenes.splice(idx, 1);
        },
        setAgrupacion(id){
            this.$refs.selectViviendas.setIdPadre(id)
        },
        setVivienda(id){
            this.llamado.id_vivienda = id
        },
        handleChange(file, fileList){    
            const isIMG = (file.raw.type === 'image/png' || file.raw.type === 'image/jpeg');
            this.imagenes = fileList

            if (!isIMG){
                this.$message.error('Solo se permiten imágenes')
                this.removeImage(file)
            }

        },
        handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file){
            console.log(file);
        },
        removeImage(file){
            let index = _.findIndex(this.imagenes, { 'uid': file.uid })
            this.$delete(this.imagenes, index)
        },

        async guardar(){
            try {
                const valid = await this.$refs.validation.validate()
                if(!valid) return this.notificacion('','Por favor complete todos los campos')
                this.loading = true
                let formData = new FormData();
                formData.append("titulo", this.llamado.titulo)
                formData.append("descripcion", this.llamado.descripcion)
                formData.append("id_vivienda", this.llamado.id_vivienda)
                formData.append("recomendaciones", this.llamado.recomendaciones)
                this.imagenes.forEach(imagen => {
                    formData.append("imagenes[]", imagen.raw)
                })

                if (this.llamado.id_vivienda == ''){
                    this.loading = false
                    return this.notificacion('','Por favor complete los campos de la vivienda')
                }

                const {data} = await Llamados.guardar(formData)
                this.loading = false
                console.log(data.success)
                if(data.success){
                    this.$notify({
                        title: 'Guardar Llamado',
                        message: data.mensaje,
                        type: 'success'
                    })
                    this.$router.push({name:'llamados.atencion.listado'})
                }else{
                    this.$notify({
                        title: 'Guardar Llamado',
                        message: data.mensaje,
                        type: 'warning'
                    });
                }
            } catch (e){
                console.log(e)
                this.loading = false
                this.$notify({
                    title: 'Error',
                    message: 'Todos los campos son requeridos',
                    type: 'warning'
                });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.llamados-crear{
    .container-img{
        width: 120px;
        height: 120px;
        position: relative;
        i.icon-me_interesa{
            position: absolute;
            right: 3px;
            top: 3px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
}
</style>